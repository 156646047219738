import { FC, ReactText, useEffect, useState } from 'react'
import { ShopRoutes } from '@obeta/utils/lib/variables'

// Hooks
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { useTranslation } from 'react-i18next'

// Interfaces
import { IBaseProductCard } from './baseTypes'
import { ICardWithProduct } from './withProduct'
import { IWithCheckbox } from './withCheckbox'

// Models
import { OfferItemForDetailsPage } from '@obeta/models/lib/schema-models/offer-details'
import { ProductType } from '@obeta/models/lib/models/Article/Shop/Product'
import { UserV2 } from '@obeta/models/lib/models/Users/UserV2'

// Styling and Layout
import { Horizontal, Mobile } from './layouts'

import { Box, SvgIcon, Typography } from '@mui/material'
import { Counter } from '../counter/Counter'
import { TertiaryButton } from '../custom-button/CustomButton'

import { ReactComponent as ArticleIcon } from '@obeta/assets/icon/designsystem/article.svg'
import { ReactComponent as BlockIcon } from 'assets/icon/designsystem/block.svg'

import clsx from 'clsx'
import styles from '../product-card/withCheckbox.module.scss'
import actionRectangleStyles from '../dead-products/ActionsRectangle.module.scss'
import { IMAGE_SIZE_AUTHENTICATED, IMAGE_SIZE_UNAUTHENTICATED } from './Base'

interface CancelledOfferItemProps
  extends Omit<
      ICardWithProduct,
      | 'productUnit'
      | 'counter'
      | 'onInfoClicked'
      | 'product'
      | 'onAddClicked'
      | 'onDeleteClicked'
      | 'productAmount'
      | 'selectedStore'
      | 'layout'
      | 'settings'
    >,
    Omit<IWithCheckbox, 'onChange'> {
  offerItem: OfferItemForDetailsPage
  onProductImageClicked?: IBaseProductCard['productImage']['onClick']
  user: UserV2 | null
  isLoggedIn: boolean
  mobile: boolean
  tablet: boolean
}

export interface OfferItemForPdfProps {
  isCartItemForPdf?: boolean
  shoppingCartItemAmount?: number
  shoppingCartItemMinimumAmount?: number
  isCartItemForOrderPdf?: boolean
  orderPdfSupplierData?: { type: string; value: ReactText }
  orderItemAmount?: number
}
export const makeCancelledOfferItem = (
  Card: FC<IBaseProductCard & OfferItemForPdfProps>
): FC<CancelledOfferItemProps & OfferItemForPdfProps> => {
  return (props) => {
    const {
      isCartItemForPdf,
      isCartItemForOrderPdf,
      isLoggedIn,
      mobile,
      offerItem,
      onProductImageClicked,
      orderItemAmount,
      orderPdfSupplierData,
      properties = [],
      selected,
      shoppingCartItemAmount,
      shoppingCartItemMinimumAmount,
      tablet,
      user,
      ...restCardProps
    } = props
    const { t } = useTranslation()
    const history = useHistory()

    const mobileOrTablet = mobile || tablet

    const [productAmount, setProductAmount] = useState(offerItem.amount)

    useEffect(() => {
      if (offerItem.amount !== productAmount) {
        setProductAmount(offerItem.amount)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offerItem.amount])

    const offerItemTitle =
      offerItem.product.type === ProductType.custom
        ? offerItem.articleDescription
        : offerItem.product.title

    // Almost equivalent to ActionsRectangle.tsx, however the props are not applicable in this context
    function getRemovedPositionBlock() {
      return (
        <div className={clsx(actionRectangleStyles.actionsRectangle)}>
          <div className={actionRectangleStyles.contentWrapper}>
            <div className={clsx(actionRectangleStyles.flexed, actionRectangleStyles.evenMargin)}>
              <BlockIcon
                className={clsx(actionRectangleStyles.blockIcon, actionRectangleStyles.centered)}
              />
              <Typography variant={'bodyBold'}>
                {t('OFFERS.CANCELLED.ITEM_POSITION_CANCELLED')}
                {mobile && <br />}
                <span className={actionRectangleStyles.weightBody}>
                  {t('OFFERS.CANCELLED.PURCHASABLE_WITHOUT_OFFER_RELATION')}
                </span>
              </Typography>
            </div>

            <div className={actionRectangleStyles.containerDiv}>
              {
                <TertiaryButton
                  size={mobileOrTablet ? 'large' : 'small'}
                  rightIcon={<SvgIcon component={ArticleIcon} />}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    history.push(`${ShopRoutes.ArticleDetailPage}/${offerItem.product.sapId}`)
                  }}
                >
                  {!mobile && !tablet && (
                    <Typography className={actionRectangleStyles.infoReplacementText}>
                      {t('OFFERS.CANCELLED.SHOW_ARTICLE')}
                    </Typography>
                  )}
                </TertiaryButton>
              }
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className={offerItem.isCancelled ? styles.cancelledOfferItemCardWrapper : ''}>
        <Card
          stocks={null}
          onAddClicked={null}
          storeAddress={''}
          onDeleteClicked={null}
          {...restCardProps}
          data-testid="template-item-card"
          authenticated={isLoggedIn}
          title={offerItemTitle}
          properties={[
            {
              type: t<string>('ARTICLE_DETAIL.ARTICLE_NUMBER'),
              value: offerItem.product.dehaId,
            },
            ...properties,
          ]}
          isSendable={offerItem.product.isSendable}
          isCutProduct={offerItem.product.isCutProduct}
          productImage={{
            componentType: 'card',
            src: offerItem.product.imageData?.images[0]?.large ?? '',
            alt: offerItemTitle,
            supplierImage:
              offerItem.product.type === 'custom'
                ? offerItem.supplierImageData.large
                : offerItem.product.supplierImageData?.large,
            supplierId:
              offerItem.product.type === 'custom'
                ? offerItem.supplierImageData.sapId
                : offerItem.product.supplierId,
            oxomiId: offerItem.product.oxomiId,
            className: '',
            onClick: onProductImageClicked,
          }}
          productType={offerItem.product.type as ProductType}
          onInfoClicked={null}
          productAmount={productAmount}
          counter={
            isCartItemForPdf || !isLoggedIn ? null : (
              <Box sx={{}}>
                <Counter
                  stretchHorizontal={true}
                  amount={productAmount}
                  minimumAmount={0}
                  initialAmount={null}
                  variant={mobileOrTablet ? 'big' : 'small'}
                  readonly={mobileOrTablet}
                  disabled={false}
                  changeProductAmount={() => {
                    //
                  }}
                  onTextFieldClicked={() => {
                    //
                  }}
                />
              </Box>
            )
          }
          isCartItemForPdf={isCartItemForPdf}
          isCartItemForOrderPdf={isCartItemForOrderPdf}
          settings={{
            labels: 'only-icon',
            statusesDirection: mobile ? 'vertical' : 'horizontal',
            stretch: true,
          }}
          layout={(layoutProps) => {
            if (mobile) {
              return (
                <Mobile
                  leftAdornments={null}
                  {...layoutProps}
                  topAdornments={null}
                  bottomAdornments={null}
                  imageSize={
                    layoutProps.authenticated
                      ? IMAGE_SIZE_AUTHENTICATED
                      : IMAGE_SIZE_UNAUTHENTICATED
                  }
                  values={null}
                  deadProduct={offerItem.isCancelled}
                />
              )
            }

            if (tablet) {
              const { counter, ...restLayoutProps } = layoutProps
              return (
                <Horizontal
                  bottomRightAdornments={undefined}
                  topAdornments={null}
                  leftAdornments={null}
                  bottomAdornments={null}
                  {...restLayoutProps}
                  className={styles.tablet}
                  counter={null}
                  mobile={true}
                  mapValues={null}
                  deadProduct={offerItem.isCancelled}
                />
              )
            }

            return (
              <Horizontal
                bottomRightAdornments={undefined}
                topAdornments={null}
                bottomAdornments={null}
                {...layoutProps}
                leftAdornments={null}
                mobile={true}
                mapValues={null}
                isCartItemForPdf={isCartItemForPdf}
                isCartItemForOrderPdf={isCartItemForOrderPdf}
                shoppingCartItemMinimumAmount={shoppingCartItemMinimumAmount}
                shoppingCartItemAmount={shoppingCartItemAmount}
                orderPdfSupplierData={orderPdfSupplierData}
                orderItemAmount={orderItemAmount}
                deadProduct={offerItem.isCancelled}
              />
            )
          }}
          {...restCardProps}
        />
        {getRemovedPositionBlock()}
        <div className={styles.cancelledOfferItemUpperOverlay} />
      </div>
    )
  }
}
