import { useTranslation } from 'react-i18next'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { ApolloQueryResult, useApolloClient } from '@apollo/client'
import { GetPricesAndStockQuery, GetPricesAndStockQueryVariables } from '@obeta/schema'
import { GET_PRICES_AND_STOCK_QUERY } from '../queries/getPricesAndStock'

export const useProductCard = () => {
  const client = useApolloClient()
  const { t } = useTranslation()

  const getPricesAndStock = async (
    sapIds: string[],
    warehouseIds: string[]
  ): Promise<ApolloQueryResult<GetPricesAndStockQuery>> => {
    return await client.query<GetPricesAndStockQuery, GetPricesAndStockQueryVariables>({
      query: GET_PRICES_AND_STOCK_QUERY,
      variables: {
        sapIds,
        warehouseIds,
      },
    })
  }

  const setAmountRoundUpNotification = (
    id: string,
    amount: number,
    userInputAmount: number,
    unit: string
  ) => {
    getEventSubscription().next({
      type: EventType.Toast,
      notificationType: NotificationType.AmountRoundUp,
      id,
      options: {
        id,
        amountRoundUpNotificationHeading: t('PRODUCT.CARD.NOTIFICATION_AMOUNT_ROUND_UP.HEADING', {
          id,
          userInputAmount,
          unit,
        }),
        amountRoundUpNotificationBody: t('PRODUCT.CARD.NOTIFICATION_AMOUNT_ROUND_UP.BODY', {
          amount,
          unit,
        }),
      },
    })
  }

  return {
    getPricesAndStock,
    setAmountRoundUpNotification,
  }
}
