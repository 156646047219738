import { Box, Skeleton, Typography, TypographyTypeMap } from '@mui/material'
import { PricePermissions } from '@obeta/models/lib/models/Users/UserV2'
import { normalizePrice } from '@obeta/utils/lib/data-formatter/normalizePrice'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { NoPrice } from '../no-price/NoPrice'
import styles from './ProductPrice.module.scss'
import { ICartItemForPdfProps } from '../product-card/makeCartItem'
import stylesForPdf from '../shoppingcart/pdf/ShoppingCartPDFNoOrderYet.module.scss'
import clsx from 'clsx'
import { LayoutType } from '../product-card/layouts'
export interface ProductPriceRenderConfig {
  mainPriceFont?: TypographyTypeMap['props']['variant']
}

interface IProductPriceProps {
  oldPrice?: string | number | null
  hideOldPriceElement?: boolean
  available: boolean
  price: {
    value: number | string
    loading: boolean
    currency: string
  }
  title: string
  layout?: LayoutType
  renderConfig?: ProductPriceRenderConfig
}

export const ProductPrice: React.FC<IProductPriceProps & ICartItemForPdfProps> = (props) => {
  const {
    oldPrice,
    hideOldPriceElement,
    price,
    available,
    title,
    isCartItemForPdf,
    renderConfig = {},
  } = props

  const { mainPriceFont = 'boldText' } = renderConfig

  let priceEl: React.ReactElement | null = null
  let oldPriceEl: React.ReactElement | null = null
  if (available) {
    priceEl = (
      <Typography
        className={
          isCartItemForPdf
            ? clsx(styles.typography, stylesForPdf.fontSize12px)
            : clsx(styles.typography)
        }
        variant={mainPriceFont}
      >
        {price.loading ? (
          <Skeleton animation="wave" variant="rectangular" height={24} width={80} />
        ) : (
          `${price.value}`
        )}
      </Typography>
    )
    oldPriceEl = (
      <Typography
        className={clsx(styles.typography, {
          [stylesForPdf.fontSize8px]: isCartItemForPdf,
        })}
        variant={'xSmallTextLineThrough'}
      >
        {Boolean(oldPrice) && oldPrice}
      </Typography>
    )
  } else {
    priceEl = <NoPrice currency={price.currency} />
  }

  return (
    <Box className={styles.price}>
      {!hideOldPriceElement && oldPriceEl}
      {priceEl}
      <Typography
        className={
          isCartItemForPdf ? clsx(styles.typography, stylesForPdf.fontSize10px) : styles.typography
        }
        color={'text.secondary'}
        variant={'smallText'}
      >
        {title}
      </Typography>
    </Box>
  )
}

export type PricesTypes =
  | keyof Omit<typeof PricePermissions, 'NoPrice'>
  | 'MetalAdition'
  | 'TotalPrice'
  | 'BulkPrice'
  | 'OfferPrice'

// TODO: even though PricePermissions are stacking we decided not to use map
// (map like { Purchase: 10, List: 5 }; const userPermission = 10. userPermission > List -> cool, "List" price is available)
// why not?
export const isPricePermitted = (
  priceType: PricesTypes,
  userPermission: PricePermissions | undefined
) => {
  if (priceType === 'MetalAdition' && userPermission === PricePermissions.PurchasePrice) {
    return true
  }

  if (userPermission === PricePermissions.PurchasePrice) {
    // Bulkprice and TotalPrice has same permission level as PurchasePrice.
    return true
  }

  if (userPermission === PricePermissions.CatalogPrice) {
    return priceType === 'CatalogPrice' || priceType === 'ListPrice'
  }

  // TODO: what about total and bulk prices???

  return priceType === userPermission
}

export const mapPriceValue = (d: {
  type: PricesTypes
  value: number | undefined
  unit: string
  currency: string
  priceDimension?: number
}) => {
  const p = normalizePrice(d.value, d.currency)
  if (!d.unit) {
    return p
  }

  const isDimensionIs100 = d.priceDimension === 100
  const withPercent: PricesTypes[] = [
    'CatalogPrice',
    'ListPrice',
    'PurchasePrice',
    'MetalAdition',
    'BulkPrice',
    'OfferPrice',
  ]
  if (isDimensionIs100 && withPercent.indexOf(d.type) !== -1) {
    return `${p} / % ${d.unit}`
  }

  return `${p} / ${d.unit}`
}

const getPriceLabel = (type: PricesTypes) => {
  switch (type) {
    case 'PurchasePrice':
      return 'PURCHASE'
    case 'CatalogPrice':
      return 'CATALOG'
    case 'ListPrice':
      return 'LIST'
    case 'MetalAdition':
      return 'METAL'
    case 'TotalPrice':
      return 'TOTAL'
    case 'BulkPrice':
      return 'BULK'
    case 'OfferPrice':
      return ''
    default: {
      const label: never = type
      return label
    }
  }
}

const orderOfPrices: {
  [key in Exclude<PricesTypes, 'OfferPrice'>]: number
} = {
  ListPrice: 0,
  CatalogPrice: 1,
  PurchasePrice: 2,
  TotalPrice: 3,
  MetalAdition: 4,
  BulkPrice: 5,
}

export interface IMapProductPricesProps {
  prices: {
    type: PricesTypes
    value: number | undefined
    oldValue?: number | undefined
    hideOldPriceElement?: boolean | undefined
    loading: boolean
    unit: string
  }[]
  layout?: LayoutType
  currency: string
  render: (priceElements: React.ReactElement[]) => React.ReactElement
  pricePermissions: PricePermissions | undefined | false
  priceDimension?: number
  renderConfig?: ProductPriceRenderConfig
}

export const MapProductPrices: React.FC<IMapProductPricesProps & ICartItemForPdfProps> = (
  props
) => {
  const {
    prices,
    render,
    currency,
    pricePermissions,
    isCartItemForPdf,
    priceDimension,
    isCartItemForOrderPdf = false,
    renderConfig,
  } = props
  const { t } = useTranslation()

  // TODO: should we filter prices before sort??

  const sorted = [...prices].sort((p1, p2) => {
    return orderOfPrices[p1.type] - orderOfPrices[p2.type]
  })

  const mapped = sorted.map((price) => {
    const isOldPriceVisible = price.value && price.oldValue && price.value < price.oldValue
    return (
      <ProductPrice
        key={price.type}
        renderConfig={renderConfig}
        available={
          pricePermissions !== false ? isPricePermitted(price.type, pricePermissions) : true
        }
        layout={props.layout}
        price={{
          value: mapPriceValue({
            type: price.type,
            value: price.value,
            unit: price.unit,
            currency,
            priceDimension,
          }),
          loading: price.loading,
          currency: currency,
        }}
        oldPrice={
          !isCartItemForOrderPdf && price.oldValue && isOldPriceVisible
            ? mapPriceValue({
                type: price.type,
                value: price.oldValue,
                unit: price.unit,
                currency,
                priceDimension,
              })
            : null
        }
        hideOldPriceElement={price.hideOldPriceElement}
        title={t<string>(`SHOPPING_CART.PRICES.${getPriceLabel(price.type)}`)}
        isCartItemForPdf={isCartItemForPdf}
      />
    )
  })

  return render(mapped)
}
