import React, { ChangeEventHandler, FC, ReactText, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IMapProductPricesProps } from '../product-price/ProductPrice'
import { BaseCardCounterProps, IBaseProductCard } from './baseTypes'
import { createRenderPricesBlock } from './layouts/renderPricesBlock'
import { IWithCheckbox } from './withCheckbox'
import { ICardWithProduct } from './withProduct'
import { useStocksMap } from '@obeta/data/lib/hooks/useStocksMap'
import { StoreV2 } from '@obeta/models/lib/models/Stores/StoreV2'
import { UserV2 } from '@obeta/models/lib/models/Users/UserV2'
import { Counter } from '../counter/Counter'
import { PopoverMobileCounter } from '../counter/MobileCounter'
import { Box } from '@mui/material'
import { Horizontal, Mobile } from './layouts'
import styles from '../product-card/withCheckbox.module.scss'
import stylesCheckbox from './withCheckbox.module.scss'
import { Bottom } from './layouts/Bottom'
import { AddItemsToCartButton } from '../add-items-to-cart-button/AddItemsToCartButton'
import { ShoppingCartV2 } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import cartTemplateStyles from './makeCartTemplateItem.module.scss'
import { ProductType } from '@obeta/models/lib/models/Article/Shop/Product'
import { OfferItemForDetailsPage } from '@obeta/models/lib/schema-models/offer-details'
import { DropdownTemplatesBase } from '../dropdown-templates/DropdownTemplates'
import { DropdownTemplatesType } from '@obeta/models/lib/models/CartTemplates/CartTemplate'
import { usePopoverState } from '@obeta/data/lib/hooks/usePopoverState'
import { Checkbox } from '../checkbox/Checkbox'
import clsx from 'clsx'
import { IdsTransferProductButton } from '../ids/IdsTransferProductButton'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import makeItemStyles from './makeItem.module.scss'
import { useParams } from '@obeta/data/lib/hooks/useHistoryApi'
import { IMAGE_SIZE_AUTHENTICATED, IMAGE_SIZE_UNAUTHENTICATED } from './Base'
import { OfferItemTextBubble } from '../offers/OfferItemTextBubble'
import { useIsIdsTransferBackPossible } from '@obeta/data/lib/hooks/ids/useIsIdsTransferBackPossible'

interface OfferItemProps
  extends Omit<
      ICardWithProduct,
      | 'productUnit'
      | 'counter'
      | 'onAddClicked'
      | 'onInfoClicked'
      | 'product'
      | 'onDeleteClicked'
      | 'productAmount'
      | 'stocks'
      | 'layout'
      | 'settings'
    >,
    IWithCheckbox {
  changeProductAmount: (offerItemPosition: number, amount: number) => void
  offerItem: OfferItemForDetailsPage
  onAddClicked: (offerItem: OfferItemForDetailsPage) => void
  loadingPrices?: boolean
  onProductImageClicked?: IBaseProductCard['productImage']['onClick']
  onCounterValidation?: BaseCardCounterProps['onInputValidation']
  selectedStore: StoreV2 | undefined
  user: UserV2 | null
  isLoggedIn: boolean
  mobile: boolean
  modifiedAmount?: number // Displays modified amount in favour of origin offer item amount
  tablet: boolean
  withCheckbox: boolean
  selected: boolean
  onChange: ChangeEventHandler<HTMLInputElement>
  carts: ShoppingCartV2[]
  selectedCart: ShoppingCartV2 | undefined
  onAddToCart?: (cart: ShoppingCartV2, amount?: number, userInputAmount?: number) => void
}

export interface OfferItemForPdfProps {
  isCartItemForPdf?: boolean
  shoppingCartItemAmount?: number
  shoppingCartItemMinimumAmount?: number
  isCartItemForOrderPdf?: boolean
  orderPdfSupplierData?: { type: string; value: ReactText }
  orderWithPrice?: boolean
  orderItemAmount?: number
}
export const makeOfferItem = (
  Card: FC<IBaseProductCard & OfferItemForPdfProps>
): FC<OfferItemProps & OfferItemForPdfProps> => {
  return (props) => {
    const {
      shoppingCartItemAmount,
      shoppingCartItemMinimumAmount,
      offerItem,
      onAddClicked,
      changeProductAmount,
      onAddToCart,
      carts,
      selectedCart,
      properties = [],
      loadingPrices,
      onProductImageClicked,
      onCounterValidation,
      selectedStore,
      isCartItemForPdf,
      isCartItemForOrderPdf,
      orderWithPrice,
      user,
      isLoggedIn,
      mobile,
      modifiedAmount,
      tablet,
      withCheckbox,
      selected,
      onChange,
      orderPdfSupplierData,
      orderItemAmount,
      ...restCardProps
    } = props
    const layoutType = 'horizontal'
    const { desktop } = useBreakpoints()
    const params = useParams()
    const { t } = useTranslation()
    const showIdsTransferBackButton = useIsIdsTransferBackPossible()
    const isLoadingPrices = Boolean(loadingPrices)
    const mobileOrTablet = mobile || tablet
    const { handleClick: showDropdownTemplates, ...dropdownTemplates } = usePopoverState()
    const priceDimension = offerItem.product.priceDimension
    const productPrice = offerItem.product.prices
    const offerNetPrice = offerItem.netPrice
    const unit = offerItem.product.unit
    const stock = offerItem.product.stock
    const prices: IMapProductPricesProps['prices'] = [
      {
        type: 'PurchasePrice',
        value: offerNetPrice,
        loading: isLoadingPrices,
        unit: unit ?? '',
      },
    ]

    // Note: Custom (aka diverse) products don't have set list prices
    if (productPrice?.listPrice) {
      prices.push({
        type: 'ListPrice',
        value: productPrice?.listPrice,
        loading: isLoadingPrices,
        unit: unit ?? '',
      })
    }

    const [productAmount, setProductAmount] = useState(modifiedAmount ?? offerItem.amount)

    const [showMobileCounter, setShowMobileCounter] = useState(false)
    const mobileCounterAvailable = Boolean(mobileOrTablet)
    useEffect(() => {
      if (offerItem.amount !== productAmount) {
        modifiedAmount ? setProductAmount(modifiedAmount) : setProductAmount(offerItem.amount)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offerItem.amount, modifiedAmount])

    const minimumAmount = offerItem.product.minimumAmount

    const stocksMap = useStocksMap({
      isOnlineCurrentNotAvailable: offerItem.product.isCurrentlyNotAvailable,
      stocks: stock ?? [],
      user,
    })

    const leftAdornments = withCheckbox
      ? [
          <Checkbox
            key={offerItem.offerItemPosition}
            checked={selected}
            className={stylesCheckbox.checkbox}
            onChange={onChange}
          />,
        ]
      : null

    const actions: JSX.Element[] = []

    const topAdornments: JSX.Element[] = []

    topAdornments.push(
      <OfferItemTextBubble
        key={`${offerItem.sapId}-${offerItem.offerItemPosition}`}
        prefix={t('OFFERS.ITEM.POSITION_ABBREVIATED')}
        title={offerItem.offerItemPosition as unknown as string}
      />
    )

    if (offerItem.readingDirectoryNumber !== '') {
      topAdornments.push(
        <OfferItemTextBubble
          key={`${offerItem.offerItemPosition}-${offerItem.readingDirectoryNumber}`}
          prefix={t('OFFERS.ITEM.READING_DIRECTORY_NUMBER_ABBREVIATED')}
          title={offerItem.readingDirectoryNumber}
        />
      )
    }
    if (
      offerItem.productType === 'AlternativePosition' ||
      offerItem.productType === 'RequirementItem'
    ) {
      topAdornments.push(
        <OfferItemTextBubble
          key={`${offerItem.offerItemPosition}-${offerItem.productType}`}
          title={
            offerItem.productType === 'AlternativePosition'
              ? t('OFFERS.ITEM.ALTERNATIVE_POSITION')
              : t('OFFERS.ITEM.REQUIREMENT_ITEM')
          }
          type={'productType'}
        />
      )
    }
    const offerItemTitle =
      offerItem.product.type === ProductType.custom
        ? offerItem.articleDescription
        : offerItem.product.title

    const onCounterSubmit = useCallback(
      (amount: number, userInputAmount: number) => {
        if (selectedCart && onAddToCart) {
          onAddToCart(selectedCart, amount, userInputAmount)
        }
      },
      [selectedCart, onAddToCart]
    )

    /**
     * Update amount by number or SetStateAction.
     * @param value Number | SetStateAction
     */
    const updateAmount = (value: number | ((prev: number) => number)) => {
      if (typeof value === 'number') {
        if (modifiedAmount || value !== offerItem.amount) {
          setProductAmount(value)
          changeProductAmount(offerItem.offerItemPosition, value)
        }
      } else {
        const funcValue = value(productAmount)
        if (modifiedAmount || funcValue !== offerItem.amount) {
          setProductAmount(funcValue)
          changeProductAmount(offerItem.offerItemPosition, funcValue)
        }
      }
    }

    const bottomExtension = (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {!desktop && showIdsTransferBackButton && (
          <div className={clsx(makeItemStyles.subActions, tablet && makeItemStyles.compact)}>
            {showIdsTransferBackButton && (
              <div className={makeItemStyles.transferIdsButton}>
                <IdsTransferProductButton
                  sapId={offerItem.product.sapId}
                  amount={productAmount}
                  offerId={params.id}
                  offerItemPosition={offerItem.offerItemPosition}
                />
              </div>
            )}
          </div>
        )}
      </>
    )
    return (
      <Card
        onDeleteClicked={null}
        {...restCardProps}
        data-testid="template-item-card"
        storeAddress={selectedStore?.address.name1 ?? ''}
        authenticated={isLoggedIn}
        showIdsTransferButton
        inlineLabelsAndSubActions
        title={offerItemTitle}
        offerId={params.id}
        offerItemPosition={offerItem.offerItemPosition}
        properties={[
          {
            type: t<string>('ARTICLE_DETAIL.ARTICLE_NUMBER'),
            value: offerItem.product.dehaId,
          },
          ...properties,
        ]}
        isSendable={offerItem.product.isSendable}
        isCutProduct={offerItem.product.isCutProduct}
        productImage={{
          componentType: 'card',
          src: offerItem.product.imageData?.images[0]?.large ?? '',
          alt: offerItemTitle,
          supplierImage:
            offerItem.product.type === 'custom'
              ? offerItem.supplierImageData.large
              : offerItem.product.supplierImageData?.large,
          supplierId:
            offerItem.product.type === 'custom'
              ? offerItem.supplierImageData.sapId
              : offerItem.product.supplierId,
          oxomiId: offerItem.product.oxomiId,
          className: '',
          onClick: onProductImageClicked,
        }}
        productType={offerItem.product.type as ProductType}
        stocks={stocksMap}
        onAddClicked={
          (offerItem.product.type as ProductType) !== ProductType.custom
            ? (e) => {
                onAddClicked(offerItem)
                showDropdownTemplates(e)
              }
            : null
        }
        onInfoClicked={null}
        productAmount={productAmount}
        counter={
          isCartItemForPdf || !isLoggedIn ? null : (
            <Box sx={{}}>
              <Counter
                stretchHorizontal={true}
                amount={productAmount}
                minimumAmount={minimumAmount || 0}
                initialAmount={null}
                variant={mobileOrTablet ? 'big' : 'small'}
                readonly={mobileCounterAvailable}
                disabled={false}
                changeProductAmount={updateAmount}
                onTextFieldClicked={() => {
                  setShowMobileCounter(true)
                }}
                onSubmit={onCounterSubmit}
              />
              {mobileCounterAvailable && (
                <PopoverMobileCounter
                  open={showMobileCounter}
                  onClose={function () {
                    setShowMobileCounter(false)
                  }}
                  unit={offerItem.product.unit}
                  initialAmount={productAmount}
                  minimumAmount={minimumAmount || 0}
                  maxAcceptableAmount={9999}
                  onAccept={function (value: number): void {
                    updateAmount(value)
                    setShowMobileCounter(false)
                  }}
                />
              )}
            </Box>
          )
        }
        isCartItemForPdf={isCartItemForPdf}
        isCartItemForOrderPdf={isCartItemForOrderPdf}
        settings={{
          labels: 'only-icon',
          statusesDirection: mobile ? 'vertical' : 'horizontal',
          stretch: true,
        }}
        layout={(layoutProps) => {
          const bottomAdornments =
            layoutProps.authenticated && offerItem.product
              ? [
                  <AddItemsToCartButton
                    key="add-items-to-cart-button"
                    carts={carts}
                    selectedCart={selectedCart}
                    mobile={false}
                    product={offerItem.product}
                    productAmount={productAmount}
                    size={mobileOrTablet ? 'large' : 'small'}
                    updateCart={onAddToCart}
                    algoliaUserToken={user?.algoliaUserToken}
                    disableStandardAdd={true}
                  />,
                ]
              : null

          const renderPricesBlock = createRenderPricesBlock(
            {
              prices,
              pricePermissions: user?.permissions?.Global_canReadPrices,
              currency: productPrice?.currency || '',
              priceDimension,
            },
            layoutType,
            isLoggedIn,
            !!isCartItemForPdf,
            isCartItemForOrderPdf,
            orderWithPrice
          )
          const actionsElements = layoutProps.actionsElements.concat(actions)
          if (mobile) {
            return (
              <>
                <Mobile
                  {...layoutProps}
                  leftAdornments={leftAdornments}
                  topAdornments={topAdornments}
                  bottomAdornments={bottomAdornments}
                  actionsElements={actionsElements}
                  imageSize={
                    layoutProps.authenticated
                      ? IMAGE_SIZE_AUTHENTICATED
                      : IMAGE_SIZE_UNAUTHENTICATED
                  }
                  values={renderPricesBlock()}
                  bottomExtension={bottomExtension}
                />
                {(offerItem.product.type as ProductType) !== ProductType.custom && (
                  <DropdownTemplatesBase
                    dropdown={dropdownTemplates}
                    mobile={false}
                    productsToAdd={[{ productId: offerItem.product.sapId, amount: productAmount }]}
                    templatesType={DropdownTemplatesType.ADD_ARTICLES}
                  />
                )}
              </>
            )
          }

          if (tablet) {
            const { counter, ...restLayoutProps } = layoutProps
            return (
              <>
                <Horizontal
                  {...restLayoutProps}
                  className={styles.tablet}
                  counter={null}
                  leftAdornments={leftAdornments}
                  topAdornments={topAdornments}
                  bottomExtension={bottomExtension}
                  bottomAdornments={[
                    <Bottom
                      key="bottom-adornments"
                      className={cartTemplateStyles.bottomTablet}
                      direction="horizontal"
                    >
                      {counter}
                      {bottomAdornments}
                    </Bottom>,
                  ]}
                  bottomRightAdornments={null}
                  mobile={true}
                  actionsElements={actionsElements}
                  mapValues={renderPricesBlock}
                />
                {(offerItem.product.type as ProductType) !== ProductType.custom && (
                  <DropdownTemplatesBase
                    dropdown={dropdownTemplates}
                    mobile={false}
                    productsToAdd={[{ productId: offerItem.product.sapId, amount: productAmount }]}
                    templatesType={DropdownTemplatesType.ADD_ARTICLES}
                  />
                )}
              </>
            )
          }

          return (
            <>
              <Horizontal
                {...layoutProps}
                leftAdornments={leftAdornments}
                topAdornments={topAdornments}
                bottomRightAdornments={bottomAdornments}
                bottomAdornments={null}
                mobile={true}
                actionsElements={actionsElements}
                mapValues={renderPricesBlock}
                isCartItemForPdf={isCartItemForPdf}
                isCartItemForOrderPdf={isCartItemForOrderPdf}
                shoppingCartItemMinimumAmount={shoppingCartItemMinimumAmount}
                shoppingCartItemAmount={shoppingCartItemAmount}
                orderPdfSupplierData={orderPdfSupplierData}
                orderWithPrice={orderWithPrice}
                orderItemAmount={orderItemAmount}
              />
              {(offerItem.product.type as ProductType) !== ProductType.custom && (
                <DropdownTemplatesBase
                  dropdown={dropdownTemplates}
                  mobile={false}
                  productsToAdd={[{ productId: offerItem.product.sapId, amount: productAmount }]}
                  templatesType={DropdownTemplatesType.ADD_ARTICLES}
                />
              )}
            </>
          )
        }}
        {...restCardProps}
      />
    )
  }
}
