import { ShoppingCartItem } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IMapProductPricesProps } from '../product-price/ProductPrice'
import { BaseCardCounterProps, IBaseProductCard } from './baseTypes'
import { createRenderPricesBlock } from './layouts/renderPricesBlock'
import { IWithCheckbox } from './withCheckbox'
import { ICardWithProduct } from './withProduct'
import { ShoppingCartCounter } from '../articles/ShoppingCartCounter'
import { useStocksMap } from '@obeta/data/lib/hooks/useStocksMap'
import { StoreV2 } from '@obeta/models/lib/models/Stores/StoreV2'
import { UserV2 } from '@obeta/models/lib/models/Users/UserV2'
import { Counter } from '../counter/Counter'
import { PopoverMobileCounter } from '../counter/MobileCounter'
import { Box, Stack, Typography } from '@mui/material'
import { Horizontal, Mobile } from '../product-card/layouts'
import styles from './withCheckbox.module.scss'
import { ActionsRectangle } from '../dead-products/ActionsRectangle'
import { ReplacementArticleDialog } from '../replacement-articles/ReplacementArticleDialog'

// UI
import { Checkbox } from '../checkbox/Checkbox'
import { IMAGE_SIZE_AUTHENTICATED, IMAGE_SIZE_UNAUTHENTICATED } from './Base'
import { RemovedPosition } from './RemovedPosition'

export interface ICartItemProps
  extends Omit<
      ICardWithProduct,
      | 'productUnit'
      | 'counter'
      | 'onAddClicked'
      | 'onInfoClicked'
      | 'product'
      | 'onDeleteClicked'
      | 'productAmount'
      | 'stocks'
      | 'layout'
      | 'settings'
    >,
    Omit<IWithCheckbox, 'onChange'> {
  changeProductAmount: (itemId: string, amount: number, sapId?: string) => void
  shoppingCartItem: ShoppingCartItem
  onDeleteClicked: (shoppingCartItem: ShoppingCartItem) => void
  loadingPrices?: boolean
  onProductImageClicked?: IBaseProductCard['productImage']['onClick']
  onCounterValidation?: BaseCardCounterProps['onInputValidation']
  selectedStore: StoreV2 | undefined
  user: UserV2 | null
  isLoggedIn: boolean
  mobile: boolean
  tablet: boolean
  withCheckbox?: boolean
  selected: boolean
  onAddToCartDeadProductReplacement?: (
    items: ShoppingCartItem[],
    itemOfChoice?: ShoppingCartItem
  ) => void
  deleteCartItems?: (cartItems: ShoppingCartItem[]) => void
  activeCartName?: string
  showPriceData?: boolean
  replacementItem?: boolean
  onChangeHandler?: (articleId: string, checked: boolean) => void
}

export interface ICartItemForPdfProps {
  isCartItemForPdf?: boolean
  shoppingCartItemAmount?: number
  shoppingCartItemUnit?: string
  shoppingCartItemMinimumAmount?: number
  isCartItemForOrderPdf?: boolean
  orderPdfSupplierData?: { type: string; value: React.ReactText }
  orderWithPrice?: boolean
  orderItemAmount?: number
  orderItemUnit?: string
  hideActionsElements?: boolean
  hideImage?: boolean
  disableCounter?: boolean
}

export const makeCartItem = (
  Card: React.FC<IBaseProductCard & ICartItemForPdfProps>
): React.FC<ICartItemProps & ICartItemForPdfProps> => {
  return (props) => {
    const {
      shoppingCartItemAmount,
      shoppingCartItemMinimumAmount,
      shoppingCartItem,
      shoppingCartItemUnit,
      onDeleteClicked,
      changeProductAmount,
      properties = [],
      loadingPrices,
      onProductImageClicked,
      onCounterValidation,
      selectedStore,
      isCartItemForPdf,
      isCartItemForOrderPdf,
      orderWithPrice,
      user,
      isLoggedIn,
      mobile,
      tablet,
      withCheckbox = false,
      selected,
      orderPdfSupplierData,
      orderItemAmount,
      orderItemUnit,
      replacementArticleHref,
      showReplacementSection,
      hideActionsElements,
      hideImage,
      disableCounter,
      onAddToCartDeadProductReplacement,
      deleteCartItems,
      activeCartName,
      showPriceData = true,
      replacementItem = false,
      onChangeHandler,
      ...restCardProps
    } = props

    const layoutType = 'horizontal'
    const { t } = useTranslation()

    const lp = Boolean(loadingPrices)

    const priceDimension = shoppingCartItem.product?.priceDimension
    const netPricePerUnit = (shoppingCartItem.prices?.netPrice || 0) / priceDimension
    let netPricePerUnitWhenBulkPrice
    if (
      shoppingCartItem.product.prices?.bulkPrice1 &&
      shoppingCartItem.product.bulkPrices &&
      shoppingCartItem.amount >= shoppingCartItem.product.bulkPrices[0].amount
    ) {
      netPricePerUnitWhenBulkPrice = shoppingCartItem.product.prices.bulkPrice1 / priceDimension
    }

    const strikeThroughPriceUnit =
      (shoppingCartItem.prices?.strikeThroughPrice || 0) / priceDimension

    const prices: IMapProductPricesProps['prices'] = [
      {
        type: 'PurchasePrice',
        value: shoppingCartItem.prices?.netPrice,
        loading: lp,
        unit: shoppingCartItem.product.unit,
        oldValue: shoppingCartItem.prices?.strikeThroughPrice,
      },
      {
        type: 'TotalPrice',
        value: netPricePerUnitWhenBulkPrice
          ? netPricePerUnitWhenBulkPrice * shoppingCartItem.amount
          : netPricePerUnit * shoppingCartItem.amount,
        oldValue: strikeThroughPriceUnit * shoppingCartItem.amount,
        loading: lp,
        unit: '',
      },
    ]

    if (replacementItem && mobile && shoppingCartItem.prices?.listPrice) {
      prices.push({
        type: 'ListPrice',
        value: shoppingCartItem.prices?.listPrice,
        loading: lp,
        unit: shoppingCartItem.product.unit,
      })
    }

    if ((mobile || tablet) && !replacementItem) {
      prices.shift() //remove netPrice from price array
    }

    if (shoppingCartItem.prices?.metalNeAddition) {
      let metalValue = shoppingCartItem.prices?.metalNeAddition

      // Note: For Order PDFs we get metalPrices via orderItem.orderItemPrices.priceMetal and cast it into
      // shoppingCartItem.prices, hence there is no need to recalculate the metal addition
      if (!isCartItemForOrderPdf) {
        const metalNeAdditionPerUnit = shoppingCartItem.prices?.metalNeAddition / priceDimension
        metalValue = metalNeAdditionPerUnit * shoppingCartItem.amount
      }
      prices.push({
        type: 'MetalAdition',
        value: metalValue,
        loading: lp,
        unit: '', // we don`t want to display units for product in shopping cart
      })
    }
    const [productAmount, setProductAmount] = useState(shoppingCartItem.amount)
    const [openReplacementArticleDialog, setOpenReplacementArticleDialog] = useState(false)
    const [showMobileCounter, setShowMobileCounter] = useState(false)
    const mobileCounterAvailable = Boolean(mobile || tablet)
    const skipLocalStateUpdateRef = useRef(false)

    useEffect(() => {
      if (skipLocalStateUpdateRef.current) {
        skipLocalStateUpdateRef.current = false
      } else {
        if (shoppingCartItem.amount !== productAmount) {
          setProductAmount(shoppingCartItem.amount)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shoppingCartItem.amount])

    const updateAmount = (value: number | ((prev: number) => number)) => {
      skipLocalStateUpdateRef.current = true
      if (typeof value === 'number') {
        if (changeProductAmount) {
          setProductAmount(value)
          changeProductAmount(shoppingCartItem.id, value, shoppingCartItem.sapId)
        }
      } else {
        const funcValue = value(productAmount)
        if (changeProductAmount) {
          setProductAmount(funcValue)
          changeProductAmount(shoppingCartItem.id, funcValue, shoppingCartItem.sapId)
        }
      }
    }

    const productUnit = shoppingCartItem.product.unit
    const minimumAmount = shoppingCartItem.product.minimumAmount

    const minimumAmountInfoText = `Mindestens ${minimumAmount} ${productUnit}` // TODO Use translation

    const stocksMap = useStocksMap({
      isOnlineCurrentNotAvailable: shoppingCartItem.product.isCurrentlyNotAvailable,
      stocks: shoppingCartItem.product.stock,
      user,
    })

    const leftAdornments = withCheckbox
      ? [
          <Checkbox
            key={shoppingCartItem.id}
            checked={selected}
            className={styles.checkbox}
            onChange={(event) => {
              onChangeHandler && onChangeHandler(shoppingCartItem.id, event.target.checked)
            }}
          />,
        ]
      : null

    const actions: JSX.Element[] = []

    return (
      <div className={restCardProps.deadProduct ? styles.cardWrapper : ''}>
        <Card
          {...restCardProps}
          storeAddress={selectedStore?.address.name1 ?? ''}
          authenticated={isLoggedIn}
          title={shoppingCartItem.product.title}
          properties={[
            {
              type: t<string>('ARTICLE_DETAIL.ARTICLE_NUMBER'),
              value: shoppingCartItem.product.dehaId,
            },
            ...properties,
          ]}
          isSendable={shoppingCartItem.product.isSendable}
          isCutProduct={shoppingCartItem.product.isCutProduct}
          productImage={{
            componentType: 'card',
            src: shoppingCartItem.product.imageData.images[0]?.large,
            alt: shoppingCartItem.product.title,
            supplierImage: shoppingCartItem.product.supplierImageData?.large,
            supplierId: shoppingCartItem.product.supplierId,
            oxomiId: shoppingCartItem.product.oxomiId,
            className: '',
            onClick: onProductImageClicked,
          }}
          productType={shoppingCartItem.product.type}
          stocks={stocksMap}
          onAddClicked={null}
          onInfoClicked={null}
          productAmount={productAmount}
          counter={
            isCartItemForPdf || !isLoggedIn ? null : mobile || tablet ? (
              <Box>
                <Stack direction={'column'} gap=".25rem" alignItems={'stretch'}>
                  <Counter
                    stretchHorizontal={true}
                    amount={productAmount}
                    minimumAmount={minimumAmount || 0}
                    initialAmount={null}
                    variant={'big'}
                    readonly={mobileCounterAvailable}
                    disabled={disableCounter ?? false}
                    changeProductAmount={updateAmount}
                    onTextFieldClicked={() => {
                      setShowMobileCounter(true)
                    }}
                  />
                  {!restCardProps.deadProduct && !disableCounter && (
                    <Typography color={'text.secondary'} variant={'smallText'} textAlign={'center'}>
                      {minimumAmountInfoText}
                    </Typography>
                  )}
                  {mobileCounterAvailable && (
                    <PopoverMobileCounter
                      open={showMobileCounter}
                      onClose={function () {
                        setShowMobileCounter(false)
                      }}
                      unit={shoppingCartItem.product.unit}
                      initialAmount={productAmount}
                      minimumAmount={minimumAmount || 0}
                      maxAcceptableAmount={9999}
                      onAccept={function (value: number): void {
                        updateAmount(value)
                        setShowMobileCounter(false)
                      }}
                    />
                  )}
                </Stack>
              </Box>
            ) : (
              <ShoppingCartCounter
                shoppingCartItem={shoppingCartItem}
                unit={productUnit}
                key="counter"
                minimumAmount={minimumAmount}
                amount={productAmount}
                changeProductAmount={updateAmount}
                initialAmount={null}
                onInputValidation={onCounterValidation}
                deadProduct={restCardProps.deadProduct}
                disableCounter={disableCounter}
              />
            )
          }
          onDeleteClicked={
            isCartItemForPdf || withCheckbox ? null : () => onDeleteClicked(shoppingCartItem)
          }
          isCartItemForPdf={isCartItemForPdf}
          isCartItemForOrderPdf={isCartItemForOrderPdf}
          settings={{
            labels: 'only-icon',
            statusesDirection: mobile ? 'vertical' : 'horizontal',
            stretch: true,
          }}
          layout={(layoutProps) => {
            const bottomAdornments = null
            const renderPricesBlock = showPriceData
              ? createRenderPricesBlock(
                  {
                    prices,
                    pricePermissions: user?.permissions?.Global_canReadPrices,
                    currency: shoppingCartItem.prices?.currency || '',
                    priceDimension,
                  },
                  layoutType,
                  isLoggedIn,
                  !!isCartItemForPdf,
                  isCartItemForOrderPdf,
                  orderWithPrice
                )
              : // eslint-disable-next-line react/jsx-no-useless-fragment
                () => <></>
            const actionsElements = layoutProps.actionsElements.concat(actions)

            if (mobile) {
              return (
                <Mobile
                  {...layoutProps}
                  leftAdornments={leftAdornments}
                  topAdornments={null}
                  bottomAdornments={bottomAdornments}
                  actionsElements={actionsElements}
                  imageSize={
                    layoutProps.authenticated
                      ? IMAGE_SIZE_AUTHENTICATED
                      : IMAGE_SIZE_UNAUTHENTICATED
                  }
                  values={renderPricesBlock()}
                  deadProduct={restCardProps.deadProduct ?? undefined}
                  hideActionsElements={hideActionsElements}
                  hideImage={hideImage}
                />
              )
            }

            if (tablet) {
              const { counter, ...restLayoutProps } = layoutProps
              return (
                <Horizontal
                  {...restLayoutProps}
                  className={styles.tablet}
                  counter={null}
                  leftAdornments={leftAdornments}
                  topAdornments={null}
                  bottomAdornments={bottomAdornments}
                  bottomRightAdornments={[counter]}
                  mobile={true}
                  actionsElements={actionsElements}
                  mapValues={restCardProps.deadProduct ? null : renderPricesBlock}
                  deadProduct={restCardProps.deadProduct ?? undefined}
                  hideActionsElements={hideActionsElements}
                  hideImage={hideImage}
                />
              )
            }

            return (
              <Horizontal
                {...layoutProps}
                productImageSize={isCartItemForPdf ? '80' : undefined}
                leftAdornments={leftAdornments}
                topAdornments={null}
                bottomRightAdornments={bottomAdornments}
                bottomAdornments={null}
                mobile={true}
                actionsElements={actionsElements}
                mapValues={restCardProps.deadProduct ? null : renderPricesBlock}
                isCartItemForPdf={isCartItemForPdf}
                isCartItemForOrderPdf={isCartItemForOrderPdf}
                shoppingCartItemMinimumAmount={shoppingCartItemMinimumAmount}
                shoppingCartItemAmount={shoppingCartItemAmount}
                shoppingCartItemUnit={shoppingCartItemUnit}
                orderPdfSupplierData={orderPdfSupplierData}
                orderWithPrice={orderWithPrice}
                orderItemAmount={orderItemAmount}
                orderItemUnit={orderItemUnit}
                deadProduct={restCardProps.deadProduct ?? undefined}
                hideActionsElements={hideActionsElements}
                hideImage={hideImage}
              />
            )
          }}
          {...restCardProps}
        />
        {props.deadProduct && (
          <>
            <RemovedPosition
              children={
                <ActionsRectangle
                  replacementArticleHref={replacementArticleHref ?? ''}
                  showReplacementSection={showReplacementSection}
                  onDeleteClicked={() => onDeleteClicked(shoppingCartItem)}
                  deadProductItem={shoppingCartItem}
                  setOpenReplacementArticleDialog={setOpenReplacementArticleDialog}
                />
              }
            />
            <div className={styles.deadProductItemUpperOverlay} />
          </>
        )}

        {props.deadProduct && (
          <ReplacementArticleDialog
            key={shoppingCartItem.product.sapId}
            open={openReplacementArticleDialog}
            onClose={() => {
              setOpenReplacementArticleDialog(false)
            }}
            itemOfChoice={shoppingCartItem}
            onAddToCart={
              onAddToCartDeadProductReplacement as (
                items: ShoppingCartItem[],
                itemOfChoice?: ShoppingCartItem
              ) => void
            }
            activeCartName={activeCartName as string}
          />
        )}
      </div>
    )
  }
}
