import {
  ChangeEvent,
  CSSProperties,
  FocusEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Paper, Popover, PopoverOrigin, SvgIcon, TextField, Typography } from '@mui/material'
import { TFunction, useTranslation } from 'react-i18next'
import { IconButton } from '../icon-button/IconButton'
import { ReactComponent as CloseIcon } from '@obeta/assets/icon/designsystem/close.svg'
import { ReactComponent as CheckIcon } from '@obeta/assets/icon/designsystem/check.svg'
import { Select } from '@mobiscroll/react5'
import styles from './MobileCounter.module.scss'
import clsx from 'clsx'
import { getNextValidAmount } from './helpers'
import '@obeta/assets/theme/mobiscroll.scss'
import { useKeyboardHeight } from '@obeta/data/lib/hooks/useKeyboardHeight'

export interface IMobileCounterProps {
  minimumAmount: number
  unit: string
  initialAmount?: number
  maxAcceptableAmount: number
  onAccept: (value: number) => void
  onCancel: (value: number) => void
  rowsCount?: number
}

interface IDataItem {
  value: number
}

interface IRowRendererContext {
  minimumAmount: number
  unit?: string
  t: TFunction
}

const SCROLLER_ITEM_HEIGHT = 36

function optionsRowRenderer(this: IRowRendererContext, { value }) {
  const { minimumAmount, unit, t } = this
  return (
    <div className={styles['optionsListRow']}>
      <Typography variant="body" className={styles['scrollerOptionText']}>
        {value} {unit}
      </Typography>
      {value === minimumAmount && (
        <>
          {' '}
          <Typography variant="smallText" color="text.secondary">
            ({t<string>('MAIN.MIN_COUNT')})
          </Typography>
        </>
      )}
    </div>
  )
}

export const MobileCounter: React.FC<IMobileCounterProps> = (props) => {
  const {
    minimumAmount,
    unit,
    initialAmount = minimumAmount,
    maxAcceptableAmount,
    onAccept,
    onCancel,
    rowsCount = 5,
  } = props
  const keyboardHeight = useKeyboardHeight()
  const { t } = useTranslation()
  const [inputVal, setInputVal] = useState<string | number>(initialAmount)
  const [inputValValid, setInputValValid] = useState(true)
  const [showNotification, setShowNotification] = useState(false)
  const inputRef = useRef<HTMLSpanElement>(null)
  const scroller = useRef<Select>(null)

  useEffect(() => {
    setInputVal((base) => {
      const { nextValidAmount } = getNextValidAmount(
        Number(base),
        minimumAmount,
        maxAcceptableAmount
      )
      return nextValidAmount
    })
  }, [maxAcceptableAmount, minimumAmount])

  useEffect(() => {
    const el = inputRef.current
    if (el) {
      el.textContent = inputVal.toString()
    }
    const inst = scroller.current
    if (inst) {
      inst.setVal(inputVal)
    }
  }, [inputVal])

  useEffect(() => {
    if (!inputValValid) {
      const el = inputRef.current
      if (el) {
        el.textContent = inputVal.toString()
      }
      const { isValid } = getNextValidAmount(Number(inputVal), minimumAmount, maxAcceptableAmount)
      setInputValValid(isValid)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValValid])

  useEffect(() => {
    /** autoFocus doesn't work for non-input field, so do it programmatically */
    inputRef.current?.focus()
  }, [])

  const handleFieldFocus = useCallback((e: FocusEvent<HTMLInputElement>) => {
    // Select all content of non-input but contenteditable element
    // https://gist.github.com/beccasaurus/2987019
    const range = document.createRange()
    range.selectNodeContents(e.target)
    const sel = window.getSelection()
    sel?.removeAllRanges()
    sel?.addRange(range)
  }, [])

  const data = useMemo(() => {
    const amounts: IDataItem[] = []
    for (let value = minimumAmount; value < maxAcceptableAmount; value += minimumAmount) {
      amounts.push({ value })
    }
    return amounts
  }, [maxAcceptableAmount, minimumAmount])

  const optionsRowContext = useMemo(() => ({ minimumAmount, unit, t }), [minimumAmount, unit, t])
  const rowRendererBound = optionsRowRenderer.bind(optionsRowContext)

  const scrollerOffsetTop = useMemo(
    () => (SCROLLER_ITEM_HEIGHT * rowsCount) / 2 - SCROLLER_ITEM_HEIGHT / 2,
    [rowsCount]
  )

  return (
    <Paper
      className={clsx(styles.root, {
        [styles.withWarn]: showNotification,
        [styles.withKeyboard]: keyboardHeight > 0,
      })}
    >
      <TextField
        value={inputVal}
        variant="outlined"
        className={clsx('outlined-darkGray34K', styles.textField)}
        InputProps={{
          inputComponent: 'span',
          endAdornment: (
            <Typography className={styles.textFieldAdornment} color="text.secondary" variant="body">
              {unit}
            </Typography>
          ),
        }}
        inputProps={{
          ref: inputRef,
          size: 0,
          contentEditable: true,
          className: styles.inputElement,
          inputMode: 'numeric',
          onBeforeInput: (_e) => {
            // Allow only numbers
            const e = _e as unknown as ChangeEvent<HTMLSpanElement> & { data: string }
            const state = e.target.textContent + e.data
            const parsed = Number(state)
            if (isNaN(parsed)) {
              e.preventDefault()
              return
            }
          },
          onBlur: (e) => {
            const state = e.target.textContent || ''
            const { nextValidAmount, isValid } = getNextValidAmount(
              parseInt(state),
              minimumAmount,
              maxAcceptableAmount
            )
            setInputVal(nextValidAmount)
            setInputValValid(isValid)

            if (isValid) {
              setShowNotification(false)
            } else {
              setShowNotification(true)
            }

            /** Deselect everything what was selected inside the field */
            window.getSelection()?.removeAllRanges()
          },
          onFocus: handleFieldFocus,
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault()
            if (inputRef?.current?.textContent) {
              const { nextValidAmount, isValid } = getNextValidAmount(
                parseInt(inputRef?.current?.textContent),
                minimumAmount,
                maxAcceptableAmount
              )
              setInputVal(nextValidAmount)
              setInputValValid(isValid)

              if (isValid) {
                setShowNotification(false)
                onAccept(Number(nextValidAmount))
              } else {
                setShowNotification(true)
              }
            }
          }
        }}
      />
      <IconButton
        className={styles.cancel}
        icon={<SvgIcon component={CloseIcon} />}
        onClick={() => onCancel(Number(inputVal))}
      />
      <IconButton
        className={styles.accept}
        variant="contained"
        color="secondary"
        icon={<SvgIcon component={CheckIcon} />}
        onClick={() => {
          onAccept(Number(inputVal))
          setShowNotification(false)
        }}
      />
      {showNotification && (
        <Paper className={styles.warning}>
          <Typography variant={'smallText'}>{t<string>('ALERTS.COUNTER_CORRECTED')}</Typography>
        </Paper>
      )}
      <div
        className={styles.optionsList}
        style={{ '--scrollerListOffset': `-${scrollerOffsetTop}px` } as CSSProperties}
      >
        <Select
          cssClass={styles['selectRoot']}
          scrollLock
          onChange={({ value }) => {
            if (!Number.isInteger(value)) {
              return
            }

            setInputVal(value)
            setInputValValid(true)
          }}
          ref={scroller}
          display="inline"
          theme="material"
          themeVariant="light"
          data={data}
          itemHeight={SCROLLER_ITEM_HEIGHT}
          rows={rowsCount}
          touchUi={true}
          renderItem={rowRendererBound}
        />
      </div>
    </Paper>
  )
}

interface IPopoverMobileCounterProps extends Omit<IMobileCounterProps, 'onCancel'> {
  open: boolean
  onClose: () => void
}

const popoverOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
}

export const PopoverMobileCounter: React.FC<IPopoverMobileCounterProps> = (props) => {
  const { open, onClose, ...counter } = props
  const keyboardHeight = useKeyboardHeight()

  return (
    <Popover
      transitionDuration={0}
      transformOrigin={popoverOrigin}
      anchorOrigin={popoverOrigin}
      open={open}
      classes={{ paper: styles.popover }}
      keepMounted={false}
      style={{ bottom: keyboardHeight }}
    >
      <MobileCounter {...counter} onCancel={onClose} />
    </Popover>
  )
}
