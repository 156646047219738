import styles from './ArticleStock.module.scss'
import React from 'react'
import { Box, Skeleton, Typography } from '@mui/material'
import { ProductType, Stock, StockType } from '@obeta/models/lib/models/Article/Shop/Product'
import { useTranslation } from 'react-i18next'
import { ArticleStockTooltip } from './ArticleStockTooltip'
import { useProductStockData } from '@obeta/data/lib/hooks/useProductStockData'
import { normalizeNumber } from '@obeta/utils/lib/data-formatter/normalizeNumber'
import { Tooltip } from '../tooltip/Tooltip'
import { StatusBubble } from '../status-bubble/StatusBubble'

interface IProductStockProps {
  storeAddress: string
  productType: ProductType
  stockType: StockType
  productAmount: number
  available: boolean
  stock: Stock | undefined
  name: string
  loading?: boolean
}

export const ArticleStock: React.FC<IProductStockProps> = (props) => {
  const {
    available,
    productAmount,
    name,
    storeAddress,
    stock,
    stockType,
    productType,
    loading = false,
  } = props
  const { t } = useTranslation()

  const { status, statusColor, statusDescription } = useProductStockData({
    productType,
    stockAmount: stock?.amount || 0,
    amount: productAmount,
    available,
  })

  let description = statusDescription
  if (!description) {
    description = normalizeNumber(stock?.amount || 0, 0)
  }

  return loading ? (
    <Skeleton
      animation="wave"
      variant="rectangular"
      height={16}
      width={120}
      className={styles.mr_1}
    />
  ) : (
    <Box className={styles.box}>
      <StatusBubble className={styles.circle} color={statusColor} />

      {stockType === 'online' && (
        <Typography variant={'smallText'} className={styles.typeOfStock}>
          {name}:{' '}
        </Typography>
      )}
      {stockType === 'store' && (
        <Tooltip title={storeAddress}>
          <Typography variant={'smallText'} className={styles.typeOfStock}>
            {name}:{' '}
          </Typography>
        </Tooltip>
      )}

      <ArticleStockTooltip
        amount={productAmount}
        stockType={stockType}
        available={available}
        availableStock={stock}
        storeAddress={storeAddress}
        type={productType}
        status={status}
      >
        <Typography title={description} noWrap className={styles.amount} variant={'smallTextBold'}>
          {description}{' '}
        </Typography>
        {description !== t('SHOPPING_CART.STOCK.OUT_OF_STOCK') &&
          description !== t('SHOPPING_CART.STOCK.NOT_AVAILABLE') &&
          !description.endsWith(t('SHOPPING_CART.STOCK.SPECIAL_ORDER')) && (
            <Typography variant={'smallTextBold'} className={styles.unit}>
              {stock?.unit}
            </Typography>
          )}
      </ArticleStockTooltip>
    </Box>
  )
}
