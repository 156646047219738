import { ChangeEventHandler, useEffect, useMemo, useRef, useState } from 'react'
import { AddItemsToCartButton } from '../add-items-to-cart-button/AddItemsToCartButton'
import { TopsellerTag } from '../tag/Tag'
import { ICardWithProduct } from './withProduct'
import { ReactComponent as TecSelectIcon } from '@obeta/assets/icon/tec_select_small.svg'
import styles from './makeSearchResultItem.module.scss'
import { Horizontal } from './layouts'
import { Mobile } from './layouts/Mobile'
import { PopoverMobileCounter } from '../counter/MobileCounter'
import { DropdownTemplatesBase } from '../dropdown-templates/DropdownTemplates'
import { usePopoverState } from '@obeta/data/lib/hooks/usePopoverState'
import { createRenderPricesBlock } from './layouts/renderPricesBlock'
import { Bottom } from './layouts/Bottom'
import { productHasTecSelect } from '@obeta/utils/lib/productHasTecSelect'
import { ReactComponent as VoltimumIcon } from '@obeta/assets/icon/voltimum_small.svg'
import { useDispatch } from 'react-redux'
import {
  AddCartContextEnum,
  addProductToCartGraphQL,
  CartsActionTypes,
} from '@obeta/data/lib/actions/cart-actions'
import { useActionNotification } from '@obeta/data/lib/hooks/useActionNotification'
import { ReplacementArticleSearchLink } from './ReplacementArticleSearchLink'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import clsx from 'clsx'
import { useStocksMap } from '@obeta/data/lib/hooks/useStocksMap'
import { useReplacementArticle } from '@obeta/data/lib/hooks/useReplacementArticle'
import { DropdownTemplatesType } from '@obeta/models/lib/models/CartTemplates/CartTemplate'
import { isCompleteSearchProduct } from '@obeta/models/lib/schema-models/utils/isCompleteSearchProduct'
import { MaybeCompleteSearchProduct } from '@obeta/models/lib/schema-models/search'
import { ShoppingCartV2 } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { UserV2 } from '@obeta/models/lib/models/Users/UserV2'
import { ProductCollectionActionProvider } from '@obeta/data/lib/hooks/useProductCollectionActions'
import { trackClick } from '@obeta/utils/lib/tracking'
import { useSearchDataContext } from '@obeta/data/lib/hooks/useSearchTrackingContext'
import { IdsTransferProductButton } from '../ids/IdsTransferProductButton'
import { useIsIdsTransferBackPossible } from '@obeta/data/lib/hooks/ids/useIsIdsTransferBackPossible'

export interface ISearchResultItemProps
  extends Omit<
    ICardWithProduct,
    | 'mapValues'
    | 'layout'
    | 'bottomAdornments'
    | 'counter'
    | 'topAdornments'
    | 'leftAdornments'
    | 'onDeleteClicked'
    | 'onInfoClicked'
    | 'mobile'
    | 'productAmount'
    | 'productUnit'
    | 'onAddClicked'
    | 'settings'
    | 'authenticated'
    | 'product'
  > {
  selected: boolean
  onChange?: ChangeEventHandler<HTMLInputElement>
  mobile: boolean
  tablet: boolean
  initialProductAmount?: number
  user: UserV2 | null
  isLoggedIn: boolean
  onAmountChange?: (amount: number) => void // reuse?
  carts: ShoppingCartV2[]
  selectedCart: ShoppingCartV2 | undefined
  onAddToCart?: (cart: ShoppingCartV2) => void
  autofocusOnRender?: boolean
  hasVoltimumLogo?: boolean
  isFetchingImages?: boolean
  product: MaybeCompleteSearchProduct
}

export const makeSearchResultItem = (
  Card: React.FC<ICardWithProduct>
): React.FC<ISearchResultItemProps> => {
  return function SearchResultItemBase(props) {
    const {
      product,
      onChange,
      selected,
      mobile,
      tablet,
      initialProductAmount = product.minimumAmount,
      user,
      isLoggedIn,
      onAmountChange,
      carts,
      selectedCart,
      onAddToCart,
      autofocusOnRender,
      hasVoltimumLogo,
      isFetchingImages,
      ...restCardProps
    } = props
    const [productAmount, setProductAmount] = useState(initialProductAmount)
    const onAmountChangeRef = useRef(onAmountChange)
    const dispatch = useDispatch()
    const { desktop } = useBreakpoints()

    const [showMobileCounter, setShowMobileCounter] = useState(false)
    const [updateWaitingAddItems, setUpdateWaitingAddItems] = useState(false)

    const waitForAction = useActionNotification(CartsActionTypes.AddProductToCartGraphQLResult)
    const { handleClick: showDropdownTemplates, ...dropdownTemplates } = usePopoverState()
    const tracking = useSearchDataContext()
    const tecSelectAvailable = productHasTecSelect(
      isCompleteSearchProduct(product) ? product.prices : undefined
    )

    useEffect(() => {
      onAmountChangeRef.current?.(productAmount)
    }, [productAmount])

    const topAdornments: JSX.Element[] = []

    if (product.isTopseller) {
      topAdornments.push(<TopsellerTag key="top-seller-tag" />)
    }

    if (hasVoltimumLogo) {
      topAdornments.push(
        <div key="topAdornments-voltimumIcon" className={styles.voltimumIcon}>
          <VoltimumIcon />
        </div>
      )
    }

    if (tecSelectAvailable) {
      topAdornments.push(<TecSelectIcon key="tec-select" />)
    }

    const actions = [
      // we hide this checkbox now, because we don`t have necessary functionality for this
      // TODO: return this when features are ready
      // <Checkbox
      //   onClick={(e) => {
      //     e.stopPropagation()
      //   }}
      //   key="select-action"
      //   className={styles.checkbox}
      //   onChange={onChange}
      //   checked={selected}
      // />,
    ]

    const onSubmit = (productAmount: number) => {
      if (!selectedCart) {
        return
      }

      if (!product) {
        console.warn('Add item to cart: no product to add it to cart!')
        return
      }
      setUpdateWaitingAddItems(true)
      dispatch(
        addProductToCartGraphQL({
          cartId: selectedCart.id,
          items: [{ sapId: product.sapId, amount: productAmount, title: product.title }],
          context: AddCartContextEnum.SearchResult,
        })
      )
      onAddToCart?.(selectedCart)

      waitForAction(() => {
        setUpdateWaitingAddItems(false)
      })
    }

    const productPrices = isCompleteSearchProduct(product) ? product.prices : undefined

    const mobileOrTablet = mobile || tablet
    const stocks = useStocksMap({
      isOnlineCurrentNotAvailable: false,
      stocks: isCompleteSearchProduct(product) ? product.stock ?? [] : undefined,
      user,
    })
    const { isReplacementArticleVisible, replacementArticleHref } = useReplacementArticle({
      replacementArticleSapId: product.replacementArticleSapId,
      stocks,
    })
    const showIdsTransferBackButton = useIsIdsTransferBackPossible()
    const showReplacementButton = isReplacementArticleVisible && stocks
    const replacementArticle = useMemo(
      () => (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {!desktop && (showIdsTransferBackButton || showReplacementButton) && (
            <div className={clsx(styles.subActions, tablet && styles.compact)}>
              {showReplacementButton && (
                <ReplacementArticleSearchLink
                  className={styles.replacementArticle}
                  replacementDehaIds={
                    product.replacementProducts?.map(({ dehaId }) => dehaId) ?? []
                  }
                  dehaId={product.dehaId}
                />
              )}
              {showIdsTransferBackButton && (
                <div className={styles.transferIdsButton}>
                  <IdsTransferProductButton sapId={product.sapId} amount={productAmount} />
                </div>
              )}
            </div>
          )}
        </>
      ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        replacementArticleHref,
        isReplacementArticleVisible,
        tablet,
        stocks,
        showIdsTransferBackButton,
      ]
    )
    return (
      <ProductCollectionActionProvider
        value={{
          onAddToTemplate: (sapId) =>
            trackClick(`search-add-product-to-template`, {
              queryId: tracking.queryId,
              sapId,
            }),
          onAddToCart: (sapId) =>
            trackClick('search-add-product-to-cart', { queryId: tracking.queryId, sapId }),
          onLinkClick: (sapId) =>
            trackClick('search-navigate-to-product-details', {
              queryId: tracking.queryId,
              sapId,
            }),
        }}
      >
        <Card
          user={user}
          authenticated={isLoggedIn}
          settings={{
            labels: 'only-icon',
            statusesDirection: mobile ? 'vertical' : 'horizontal',
            stretch: true,
          }}
          onAddClicked={(e) => {
            e.stopPropagation()
            showDropdownTemplates(e)
          }}
          product={product}
          showIdsTransferButton
          inlineLabelsAndSubActions
          productAmount={productAmount}
          productUnit={null}
          onDeleteClicked={null}
          isFetchingImages={isFetchingImages}
          onInfoClicked={null}
          withLabels={true}
          titleLines={mobile ? 6 : 2}
          layout={(layoutProps) => {
            const bottomAdornments = layoutProps.authenticated
              ? [
                  <AddItemsToCartButton
                    key="add-items-to-cart-button"
                    carts={carts}
                    selectedCart={selectedCart}
                    mobile={false}
                    product={product}
                    productAmount={productAmount}
                    size={mobileOrTablet ? 'large' : 'small'}
                    updateCart={onAddToCart}
                    updateWaitingAddItems={updateWaitingAddItems}
                    algoliaUserToken={user?.algoliaUserToken}
                  />,
                ]
              : null

            const renderPricesBlock = createRenderPricesBlock(
              {
                prices: [
                  {
                    type: 'PurchasePrice',
                    value: productPrices?.netPrice,
                    oldValue: productPrices?.strikeThroughPrice,
                    loading: productPrices?.netPrice === undefined,
                    unit: product.unit,
                  },
                  {
                    type: 'ListPrice',
                    value: productPrices?.listPrice,
                    loading: productPrices?.listPrice === undefined,
                    unit: product.unit,
                  },
                ],
                pricePermissions: user?.permissions?.Global_canReadPrices,
                currency: productPrices?.currency || '',
                priceDimension: product?.priceDimension,
              },
              !layoutProps.authenticated && mobile ? 'vertical' : 'horizontal',
              layoutProps.authenticated
            )
            const actionsElements = layoutProps.actionsElements.concat(actions)

            if (mobile) {
              return (
                <Mobile
                  {...layoutProps}
                  leftAdornments={null}
                  topAdornments={topAdornments}
                  bottomAdornments={bottomAdornments}
                  bottomExtension={replacementArticle}
                  actionsElements={actionsElements}
                  imageSize={'128'}
                  values={renderPricesBlock()}
                />
              )
            }

            if (tablet) {
              const { counter, ...restLayoutProps } = layoutProps

              return (
                <Horizontal
                  {...restLayoutProps}
                  className={styles.tablet}
                  counter={null}
                  leftAdornments={null}
                  topAdornments={topAdornments}
                  bottomAdornments={[
                    <Bottom
                      key="bottom-adornments"
                      className={styles.bottomTablet}
                      direction="horizontal"
                    >
                      {counter}
                      {bottomAdornments}
                    </Bottom>,
                  ]}
                  bottomExtension={replacementArticle}
                  bottomRightAdornments={null}
                  mobile={true}
                  actionsElements={actionsElements}
                  mapValues={renderPricesBlock}
                />
              )
            }

            return (
              <Horizontal
                {...layoutProps}
                leftAdornments={null}
                topAdornments={topAdornments}
                bottomRightAdornments={bottomAdornments}
                bottomAdornments={null}
                statusInAdditionalValues={false}
                actionsElements={actionsElements}
                mapValues={renderPricesBlock}
              />
            )
          }}
          counter={{
            onSubmit,
            autofocusOnRender,
            changeProductAmount: (value) => {
              setProductAmount(value)
            },
            variant: mobileOrTablet ? 'big' : 'small',
            disabled: false,
            readonly: mobileOrTablet,
            stretchVertical: mobileOrTablet,
            onTextFieldClicked: () => {
              setShowMobileCounter(true)
            },
            initialAmount: initialProductAmount,
          }}
          {...restCardProps}
        />
        {mobileOrTablet && (
          <PopoverMobileCounter
            open={showMobileCounter}
            onClose={function () {
              setShowMobileCounter(false)
            }}
            unit={product.unit}
            minimumAmount={product.minimumAmount}
            initialAmount={initialProductAmount}
            maxAcceptableAmount={9999}
            onAccept={function (value: number): void {
              setProductAmount(value)
              setShowMobileCounter(false)
            }}
          />
        )}
        <DropdownTemplatesBase
          dropdown={dropdownTemplates}
          mobile={false}
          productsToAdd={[{ productId: product.sapId, amount: productAmount }]}
          templatesType={DropdownTemplatesType.ADD_ARTICLES}
        />
      </ProductCollectionActionProvider>
    )
  }
}
