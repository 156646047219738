import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { IBaseProductCard } from './baseTypes'

export const withLinkToArticleDetail = <
  Props extends {
    onProductImageClicked?: IBaseProductCard['productImage']['onClick']
    onTitleClick?: IBaseProductCard['onTitleClick']
  }
>(
  Card: React.FC<Props>,
  getProductId: (props: Omit<Props, 'onProductImageClicked' | 'onTitleClick'>) => string
): React.FC<Omit<Props, 'onProductImageClicked' | 'onTitleClick'>> => {
  return (props) => {
    const history = useHistory()

    const push = () => {
      history.push(`${ShopRoutes.ArticleDetailPage}/${getProductId(props)}`)
    }

    return <Card {...(props as Props)} onProductImageClicked={push} onTitleClick={push} />
  }
}
