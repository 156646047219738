import React from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { ReactComponent as ArrowsLeftRightIcon } from '@obeta/assets/icon/designsystem/swap_horiz.svg'
import styles from './ReplacementArticleLink.module.scss'
import { ShopLink } from '../link/ShopLink'
import { buildSearchUrlWithDehaIds } from '@obeta/utils/lib/search/buildSearchUrlWithDehaIds'

interface Props {
  className?: string
  replacementDehaIds: string[]
  dehaId: string
}
export const ReplacementArticleSearchLink: React.FC<Props> = ({
  className,
  dehaId,
  replacementDehaIds,
}) => {
  const { t } = useTranslation()

  if (!replacementDehaIds || replacementDehaIds?.length === 0) return null

  const href = buildSearchUrlWithDehaIds(
    replacementDehaIds,
    t('REPLACEMENT.TITLE'),
    t('REPLACEMENT.FOR_PRODUCT', { id: dehaId })
  )
  if (!href) return null

  return (
    <ShopLink
      href={href}
      color={'inherit'}
      underline={'none'}
      maxWidth={'100%'}
      className={clsx(styles.replacementArticle, className)}
      trackingActionName="replacement-article-search"
    >
      <ArrowsLeftRightIcon className={styles.arrowLeftRight} />
      <Typography className={styles.replacementArticleText}>
        {t('INFO.REPLACEMENT_AVAILABLE')}
      </Typography>
    </ShopLink>
  )
}
